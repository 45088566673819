import React from 'react';
import {gql} from '@apollo/client';
import {Link} from 'gatsby';
import {useQuery} from "@apollo/client/index";
import HeaderTitle from "./HeaderTitle";

const CUSTOMER_SEARCH = gql`
 query SearchThings($searchString: String!,$searchString1: String!,$searchString2: String!) {
  vouchers(where: { _or: { offer_contains: $searchString } }) {
        id,
	    brand{name},
	    location,
	    slug,
	    logo{url},
		offer
   },
  brands(where: { _or: { name_contains: $searchString1 } }) {
      name
      vouchers{
       id,
	    brand{name},
	    location,
	    slug,
	    logo{url},
		offer
		}
  },
   tags(where: { _or: { name_contains: $searchString2 } }) {
   name,
    vouchers { 
    id
    brand {
      name
    }
    location
    slug
    logo {
      url
    }
    offer
    tags {
      name
    }
  }
  }
}
`;

const Search = (props) => {
    const isBrowser = typeof window !== "undefined";
    const loggedIn = isBrowser ? (localStorage.getItem('aruzyuser') !== null) : false;
    const title = isBrowser ? localStorage.getItem('search') : "";
    const {loading, error, data} = useQuery(CUSTOMER_SEARCH, {
        variables: {searchString: title, searchString1: title, searchString2: title},
        fetchPolicy: "cache-and-network"
    });
    if (loading) return null;
    if (error) return `Error! ${error}`;
    console.log(data);
    var filterData = [];
    data.brands.map((brand) => {
        brand.vouchers.map((voucher) => {
            filterData.push(voucher);
        });
    });
    data.tags.map((tag) => {
        tag.vouchers.map((voucher) => {
            filterData.push(voucher);
        });
    });

    data.vouchers.map((brand) => {
        filterData.push(brand);
    });

    var titles = [];
    var indexes = [];
    var index;
    for (var i = 0; i < filterData.length; i++) {
        index = titles.indexOf(filterData[i].id);
        if (index === -1) {
            titles.push(filterData[i].id);
            indexes.push(i);
        } else {
            delete filterData[i];
        }
    }

    return (
        <div>
            {VoucherTitle()}
            <div className="row">
                {renderVoucher(filterData)}
            </div>
        </div>
    )

    function noResults() {
        return (
            <div className="text-center pt-20">
                <h4>No Vouchers Found!</h4>
                <p>Maybe try adjusting your search to be a little more generic?..</p>
            </div>
        )
    }

    function VoucherTitle() {
        return (
            <div className="cat-header text-center">
                <HeaderTitle title={`Search ${title}`}/>
            </div>
        )
    }

    function renderVoucher(vouchers) {
        if (vouchers.length === 0) {
            return noResults();
        }
        return vouchers.map((voucher) => {
            return (
                <div key={"voucher-" + voucher.id} className="col-md-3">
                    <div className="card voucher">
                        <Link to={loggedIn ? "/vouchers/" + voucher.slug : '/login'}>
                            <img
                                src={`${process.env.API_URL}` + voucher.logo.url ? `${process.env.API_URL}` + voucher.logo.url : "https://via.placeholder.com/350x200.png?text=" + voucher.brand.name}
                                className="card-img-top"
                                alt={voucher.retailername + " - " + voucher.offer}/>
                            <div className="card-body">
                                <h4 className="retailer"><i className="fa fa-store"/> {voucher.brand.name}</h4>
                                <h5 className="location"><i className="fa fa-map-marker-alt"/> {voucher.location}</h5>
                                <h5 className="offer"><i className="fa fa-certificate"/> {voucher.offer}</h5>
                            </div>
                        </Link>
                    </div>
                </div>
            );
        });
    }
};

export default Search;