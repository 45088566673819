import * as React from "react"
import Header from "../partials/header"
import Footer from "../partials/footer"
import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import {ApolloProvider} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import Search from "../components/search";

const authLink = setContext((_, {headers}) => {
    return {
        headers: {
            ...headers,
        }
    }
});
const httpLink = createHttpLink({
    uri: `${process.env.API_URL}/graphql`
});
const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});


const SearchPage = (props, data) => {
    console.log(props);
    return (
        <main>
            <Header/>
            <div className="container pt-60 mb-30">
                <div className="row justify-content-center">
                    <div className="col-md-12  ">
                        <ApolloProvider client={client}>
                            <Search template={props}/>
                        </ApolloProvider>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default SearchPage